import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { actionCreators } from './store';
import reqwest from "reqwest";

import { Table, Button, Breadcrumb, Layout, Tag, Icon, Input } from 'antd';
import Highlighter from 'react-highlight-words';

const { Content, Footer } = Layout;
const data = [];

class customerManager extends React.Component{
  state = {
    data: [],
    value: 2,
    pagination: {},
    loading: false,
    searchText: '',
    searchedColumn: '',
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`要搜索的内容`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 168, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 80, marginRight: 8 }}
        >
          搜索
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 80 }}>
          重置
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    this.setState({ loading: true });
    let data = {};
    data[dataIndex] = '%'+selectedKeys.toString()+'%';
    reqwest({
      url: '//simei-api.newitd.com/v1/customer',
      method: 'get',
      data: data,
      type: 'json',
      withCredentials: true,
    }).then(res => {
      const pagination = { ...this.state.pagination };
      // Read total count from server
      pagination.total = res.data.meta.count;
      this.setState({
        loading: false,
        data: res.data.data,
        pagination,
      });
      console.log(this.state.data);
      this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
      // confirm();
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.fetch();
    this.setState({ searchText: '' });
  };

  componentDidMount() {
    console.log("Did mount!");
    this.fetch();
  }

  handleTableChange = (pagination,filters, sorter) => {
    console.log("Table Changed!");
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      page_size: pagination.pageSize,
      page: pagination.current,
    });
  };

  fetch = (params = {}) => {
    console.log('params:', params);
    this.setState({ loading: true });
    reqwest({
      url: '//simei-api.newitd.com/v1/customer',
      method: 'get',
      data: {
        page_size: 10,
        ...params,
      },
      type: 'json',
      withCredentials: true,
    }).then(res => {
      const pagination = { ...this.state.pagination };
      // Read total count from server
      pagination.total = res.data.meta.count;
      this.setState({
        loading: false,
        data: res.data.data,
        pagination,
      });
      console.log(this.state.data);
    });
  };

  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };
  render() {
    const columns = [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        align: 'center',
        fixed: 'left',
        render: text => <a href>{text}</a>,
      },
      {
        title: '客户名称',
        dataIndex: 'name',
        key: 'name',
        width: 120,
        fixed: 'left',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: '联系方式',
        dataIndex: 'phone',
        key: 'phone',
        width: 180,
        ...this.getColumnSearchProps('phone'),
      },
      {
        title: '地址',
        dataIndex: 'address',
        key: 'address',
        ...this.getColumnSearchProps('address'),
      },
      {
        title: '负责人',
        dataIndex: 'principal_user.nickname',
        key: 'principal',
        width: 120,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: 120,
        align: "center",
        render: (apply_id, v) => (
          <div>
            {
              v.status === 0 ?
                <Tag style={{textAlign: "center",margin: "0 auto"}} color="green">正常</Tag>:
                <Tag color="geekblue">无效</Tag>
            }
          </div>
        )
      },
    ];
    const { customer_list } = this.props;
    data.splice(0,data.length);
    this.props.changeApplyList(customer_list);
      return (
        <Layout>
          <Content style={{ padding: '0 50px', marginTop: 8 }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>客户管理</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ background: '#fff', padding: 6, minHeight: 790 }}>
                <Table
                  rowKey={row=>row.id}
                  columns={columns}
                  dataSource={this.state.data}
                  pagination={this.state.pagination}
                  loading={this.state.loading}
                  onChange={this.handleTableChange}
                  scroll={{ x: 1000}}
                />
              <Link to='/customer_modify'><Button type="primary">修改客户</Button></Link>
              <Link to='/customer_delete'><Button style={{ marginLeft: 48}} type="primary">删除客户</Button></Link>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Simei Manager ©2020</Footer>
        </Layout>
      );
  }
}

const mapState = (state) => ({
  user_id: state.getIn(['login','user_id']),
  customer_list: state.getIn(['all_customer','customerList']),
  permissionStatus: state.getIn(['login','user_permission']),
});

const mapDispatch = (dispatch) => ({
  changeApplyList(customer_list) {
    customer_list.size === 0 && dispatch(actionCreators.getCustomer());

  }
});

export default connect(mapState, mapDispatch)(customerManager);
