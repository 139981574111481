import React from 'react';
import { connect } from 'react-redux';
import {Table, Breadcrumb, Layout, Radio } from 'antd';
import { actionCreators } from './store';

const { Content, Footer } = Layout;
const data = [];

class applyRecords extends React.Component{
  state = {
    value: 2,
  };
  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    data.splice(0,data.length);
    this.props.changeApplyList(this.props.user_id, this.props.apply_list);
    const { apply_list } = this.props;
    const columns = [
      {
        title: '申请人',
        dataIndex: 'username',
        key: 'username',
        width: '100px'
      },
      {
        title: '申请类型',
        dataIndex: 'name',
        key: 'name',
        width: '150px'

      },
      {
        title: '金额',
        dataIndex: 'money',
        key: 'money',
        width: '150px'
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
      },
      {
        title: '申请时间',
        dataIndex: 'apply_time',
        key: 'apply_time',
        width: '200px'
      },
      {
        title: '操作',
        key: 'apply_id',
        dataIndex: 'apply_id',
        render: (apply_id,status) => (
          <span>
            {<Radio.Group defaultValue= {status.status} buttonStyle="solid">
              <Radio.Button value="未审核"  onClick={() => this.props.changeStatus(apply_id, 0)}>待审核</Radio.Button>
              <Radio.Button value="审核通过" onClick={() => this.props.changeStatus(apply_id, 1)}>通过</Radio.Button>
            </Radio.Group>}
          </span>
        ),
        width: '180px',
        fixed: 'right'
      },
    ];
    /*if(this.props.permissionStatus === 1){*/
      return (
        <Layout>
          <Content style={{ padding: '0 50px', marginTop: 8 }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>报账审核</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ background: '#fff', padding: 6, minHeight: 790 }}>
              {
                // eslint-disable-next-line array-callback-return
                apply_list.map((item) => {
                  //只把未审核的项目push到列表中
                  if(item.get('status') === 0)
                  data.push({
                    apply_id: item.get('id'),
                    username: item.get('apply_user_id'),
                    key: item.get('key'),
                    name: item.get('name'),
                    money: item.get('money')/100,
                    apply_time: item.get('apply_time'),
                    remark: item.get('remark'),
                    status: item.get('status') === 1 ? '审核通过':'未审核',
                  })
                })}
              <Table scroll={{ x: 1000 }} columns={columns} dataSource={data} />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Simei Manager ©2020</Footer>
        </Layout>
      );
    /*}else{
      return (
        <Redirect to='/'/>
      )
    }*/
  }
  componentDidMount() {

  }
  componentDidUpdate(prevProps, prevState, snapshot) {

  }
}

const mapState = (state) => ({
  user_id: state.getIn(['login','user_id']),
  apply_list: state.getIn(['all_apply','allApplyList']),
  permissionStatus: state.getIn(['login','user_permission']),
});

const mapDispatch = (dispatch) => ({
  changeApplyList(id, apply_list) {
    apply_list.size === 0 && dispatch(actionCreators.getAllApply(id));
  },
  changeStatus(a_id, status) {
    data.splice(0,data.length);
    dispatch(actionCreators.changeCheckStatus(a_id, status));
  }
});

export default connect(mapState, mapDispatch)(applyRecords);
