import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { LoginWrapper, TitleBox, LoginBox } from './style';
import { actionCreators as loginActionCreators, actionCreators } from './store'
import { Input, Form, Button } from 'antd'

class userCreator extends PureComponent {

  handleSubmit = e => {
    e.preventDefault();
    let resetStatus = 0;

    this.props.form.validateFields((err, values, resetStatus) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.login(values.user_id,values.old_password,values.new_password,this.handleResetClick);
        resetStatus = 1;
      }
    })
  };
  handleResetClick = e => {
    this.props.form.resetFields();
  };

  //新密码一致校验
  handleCheckPwd(rules,value,callback){
    let cfmPwd = this.props.form.getFieldValue('new_password_confirm');
    if(cfmPwd && cfmPwd !== value){
      callback(new Error('两次密码输入不一致'))
    }else{
      // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
      callback();
    }
  }

  //确认密码校验一致
  handleCfmPwd(rules,value,callback) {
    let loginpass = this.props.form.getFieldValue('new_password');
    if (loginpass && loginpass !== value) {
      callback(new Error('两次密码输入不一致'))
    } else {
      callback();
    }
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const { getFieldDecorator } = this.props.form;
    return (
      <LoginWrapper>
        <LoginBox>
          <TitleBox>修改密码</TitleBox>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label={"用户名"}>
              {getFieldDecorator('user_id',{
                initialValue: this.props.id,
                rules: [{ required: true, message: '请输入用户名！' }],
              })(
                <Input style={{width: 200,height: 30,margin: "10px auto"}} placeholder='登陆后获取用户名' disabled={true}/>,
              )}
            </Form.Item>
            <Form.Item label={"旧密码"}>
              {getFieldDecorator('old_password',{
                rules: [{ required: true, message: '请输入旧密码!' }],
              })(
                <Input.Password style={{width: 200,height: 30}} placeholder='旧密码' type='password'/>
              )}
            </Form.Item>
            <Form.Item label={"新密码"}>
              {getFieldDecorator('new_password',{
                rules: [{ required: true, message: '请输入新密码!' },{
                  validator:(rules,value,callback)=>
                    {this.handleCheckPwd(rules,value,callback)}
                }],
                validateFirst: true,
                validateTrigger: 'onBlur'
              })(
                <Input.Password style={{width: 200,height: 30}} placeholder='新密码' type='password'/>
              )}
            </Form.Item>
            <Form.Item label={"再输入一遍"}>
              {getFieldDecorator('new_password_confirm',{
                rules: [{ required: true, message: '请输入新密码!' },{
                  validator:(rules,value,callback)=>
                    {this.handleCfmPwd(rules,value,callback)}
                }],
                validateFirst: true,
                validateTrigger: 'onBlur'
              })(
                <Input.Password style={{width: 200,height: 30}} placeholder='确认新密码' type='password'/>
              )}
            </Form.Item>
            <Button type="primary" style={{display: "block", margin: "0 auto"}} htmlType="submit" >修改</Button>
          </Form>
        </LoginBox>
      </LoginWrapper>
    )
  }
}

const mapState = (state) => ({
  loginStatus: state.getIn(['login','login']),
  id: state.getIn(['login','user_id'])
});

const mapDispatch = (dispatch) => ({
  login(user_idElem, old_passwordElem, new_passwordElem, fun_reset){
    dispatch(actionCreators.login(user_idElem, old_passwordElem, new_passwordElem,fun_reset));
  },
  getUser() {
    dispatch(loginActionCreators.getUser())
  }
});
userCreator = Form.create({})(userCreator);
export default connect(mapState, mapDispatch)(userCreator);
