import axios from 'axios';
import * as constants from './constants';

const changeCustomerData = (result) => ({
  type: constants.CHANGE_CUSTOMER_DATA,
  customerList: result,
});

export const getCustomer = (page_size, page) => {
  return (dispatch) => {
    axios.get(
      '//simei-api.newitd.com/v1/customer',{
        params: {
          page_size: 100,
          page: 1
        }
      }
    ).then((res) => {
      console.log("获取客户列表成功！");
      const result = res.data.data.data;
      dispatch(changeCustomerData(result));
      console.log(result);
    }).catch(() => {
      console.log(page_size, page);
    })
  }
};
