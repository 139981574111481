import * as constants from './constants';
import axios from 'axios';
axios.defaults.withCredentials = true;

export const logout = () => ({
  type: constants.LOGOUT,
  value: false
});

export const getUserLogin = (user) => ({
  type: constants.GET_USER_LOGIN,
  value: true,
  user_id: user.user_id,
  username: user.nickname,
  permission: user.permission,
})

export const modifyUserInfo = (id, name, phone, address, principal, status) => {
  return (dispatch) => {
    axios.put('//simei-api.newitd.com/v1/customer/'+id,{
      id: id,
      name: name,
      phone: phone,
      address: address,
      principal_user_id: principal,
      status: status,
    }).then((res) => {
      alert('修改成功！新的客户资料为：'+id+'，'+name+'，'+phone+'，'+address+'，'+principal+'，'+status)
    }).catch((error) => {
      alert(error);
    })
  }
};

export const logout0 = () => {
  return (dispatch) => {
    axios.delete('//simei-api.newitd.com/v1/session'
    ).then((res) => {
      dispatch(logout());
    }).catch((error) => {
      console.log(error.response.data);
      alert(error.response.data.msg);
    })
  }
};

export const getUser = () => {
  return (dispatch) => {
    axios.get("http://simei-api.newitd.com/v1/session")
      .then((res) => {
        const result = res.data.data;
        console.log(result.user_id);
        dispatch(getUserLogin(result));
      })
  }
};
