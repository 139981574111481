import styled from "styled-components";
import BG_logoPic from "../../statics/bglogo.png";


export const BackGroundWrapper = styled.div`
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 48px;
  background: #F0F2F5;
`;

export const B_Logo = styled.div`
  margin: 0px;
  width: 100%;
  height: 100%;
  background: url(${BG_logoPic}) no-repeat;
  background-position: center;
  background-size: 45%,50%;
  background-attachment: scroll;
  background-color: rgb(0,133,208);
`;

export const VersionText = styled.div`
  position: absolute
  bottom: 10px; 
  right: 10px;
  font-size: 17px;
`