import React, {Component} from "react";
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

class buildPage extends Component {

  render() {
    return (
      <div>
        <Result
          style={{
            position: "relative",
            top: "100px",
          }}
          title="页面建设中!"
          extra={
            <Link to='/'>
              <Button type="primary" key="console">
                返回主页
              </Button>
            </Link>
          }
        />
      </div>
    )
  }

}
export default buildPage;
