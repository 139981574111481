import axios from 'axios';
import * as constants from './constants';
axios.defaults.withCredentials = true;

export const logout = () => ({
  type: constants.LOGOUT,
  value: false
});

export const test1 = () => {
  return (dispatch) => {
    axios.post('http://47.111.25.1:5000/v1/user/login',{
      username: "31702411",
      password: "31702411"
    }).then((res) => {
      console.log(res.data.msg);
    }).catch((error) => {
      console.log(error.response.data);
      alert(error.response.data.msg)
    })
  }
};

export const test2 = () => {
  return (dispatch) => {
    axios.post('http://47.111.25.1:5000/v1/user/get_user_info'
    ).then((res) => {
      console.log(res.data.data);
    }).catch((error) => {
      console.log(error.response.data.msg);
    })
  }
};

export const test3 = () => {
  return (dispatch) => {
    axios.post('http://47.111.25.1:5000/v1/user/get_user_list'
    ).then((res) => {
      console.log(res.data.data);
    }).catch((error) => {
      console.log(error.response.data.msg);
    })
  }
};

export const test4 = () => {
  return (dispatch) => {
    axios.post('http://47.111.25.1:5000/v1/user/logout'
    ).then((res) => {
      console.log(res.data.msg);
    }).catch((error) => {
      console.log(error.response.data.msg);
    })
  }
};
