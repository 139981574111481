import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoginWrapper, LoginBox, Button } from './style';
import { Link } from 'react-router-dom';

class Test extends PureComponent {
  render() {
    const { permissionStatus } = this.props;
    if(permissionStatus){
      return (
        <LoginWrapper>
          <LoginBox>
            <Link to='/user_creator'><Button>添加用户</Button></Link>
            <Link to='/user_modify'><Button>修改用户</Button></Link>
            <Link to='/user_delete'><Button>删除用户</Button></Link>
            <Link to='/customer_modify'><Button>修改客户</Button></Link>
            <Link to='/customer_delete'> <Button>删除客户</Button></Link>
          </LoginBox>
        </LoginWrapper>
      );
    }else {
      return (
        <Redirect to='/' />
      )
    }
    }
}

const mapState = (state) => ({
  loginStatus: state.getIn(['login','login']),
  permissionStatus: state.getIn(['login','user_permission'])
})

const mapDispatch = (dispatch) => ({

})

export default connect(mapState, mapDispatch)(Test);