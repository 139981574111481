import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import { Menu, Icon, Button, Popover, Divider } from 'antd';
import {
  ItemWrapper,
  RightWrapper,
  ButtonWrapper,
} from './style';
import { actionCreators as loginActionCreators } from '../../pages/login/store';

const { SubMenu } = Menu;

const content = (
  <div style={{width: 100}}>
    <Link to='/change_password'><p style={{marginTop: 15, textAlign: "center"}}>修改密码</p></Link>
    <Divider />
    <Link to='/building'><p style={{marginBottom: 15, textAlign: "center"}}>个人资料</p></Link>
  </div>
);

class Header2 extends React.Component {
  state = {
    current: 'mail',
    size: 'default',
  };

  handleClick = e => {
    console.log('click ', e);
    this.setState({
      current: e.key,
    });
  };

  render() {
    const { size } = this.state;
    const { login, logout, getUser, username, userType } = this.props;
    return (
      <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">

        <Menu.Item key="home" className="home">
          <Link to='/'>
            <ItemWrapper>
              <Icon type="home" />
              主页
            </ItemWrapper>
          </Link>
        </Menu.Item>

        {
          username === null?
            null:
            <SubMenu
              title={
                <ItemWrapper>
              <span className="accounting items">
                <Icon type="account-book" />
                报账
              </span>
                </ItemWrapper>
              }
            >
              <Menu.ItemGroup title="操作">
                <Menu.Item key="setting:1"><Link to='/apply_submit'>提交申请</Link></Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title="个人">
                <Menu.Item key="setting:2"><Link to='/apply_records'>我的申请</Link></Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
        }
        {/*{*/}
        {/*  username === null?*/}
        {/*    null:*/}
        {/*    <SubMenu*/}
        {/*      title={*/}
        {/*        <ItemWrapper>*/}
        {/*      <span className="accounting items">*/}
        {/*        <Icon type="account-book" />*/}
        {/*        收费*/}
        {/*      </span>*/}
        {/*        </ItemWrapper>*/}
        {/*      }*/}
        {/*      disabled*/}
        {/*    >*/}
        {/*        <Menu.Item key="setting:1"><Link to='/apply_submit'>新建收费</Link></Menu.Item>*/}
        {/*        <Menu.Item key="setting:2"><Link to='/apply_records'>我的提交</Link></Menu.Item>*/}
        {/*    </SubMenu>*/}
        {/*}*/}
        {
          username === null ?
            null:<SubMenu
              title={
                <ItemWrapper>
              <span className="accounting items">
                <Icon type="team" />
                客户
              </span>
                </ItemWrapper>
              }
            >
              <Menu.Item key="setting:8"><Link to='customer_add'>添加客户</Link></Menu.Item>
              <Menu.Item key="setting:9"><Link to='customer_manager'>客户管理</Link></Menu.Item>
            </SubMenu>
        }
        {
          userType ?
            <SubMenu
              title={
                <ItemWrapper>
              <span className="accounting items">
                <Icon type="appstore" />
                管理
              </span>
                </ItemWrapper>
              }
            >
              <Menu.ItemGroup title="审核">
                <Menu.Item key="setting:4"><Link to='apply_check'>报账审核</Link></Menu.Item>
                <Menu.Item key="setting:6"><Link to='check_records'>审核记录</Link></Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title="资金">
                <Menu.Item key="setting:3"><Link to='fund_add'>资金管理</Link></Menu.Item>
                <Menu.Item key="setting:7"><Link to='fund_records'>资金记录</Link></Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title="用户">
                <Menu.Item key="setting:5"><Link to='user_manager'>用户管理</Link></Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>:
            null
        }
        {
          username === 'simei000' ?
            <Menu.Item key="test">
              <Link to='/test'>
                <ItemWrapper>
                  <Icon type="tool" />
                  测试
                </ItemWrapper>
              </Link>
            </Menu.Item>:
            null
        }
        {
          login ?
            <RightWrapper>
            <ButtonWrapper>
              <Link to='/'><Button
                size={size}
                onClick={logout}
              >
                <Icon type="poweroff" />
                退出
              </Button></Link>
            </ButtonWrapper>
             <ButtonWrapper>
               {/*<Link to='/change_password'>*/}
                 <Popover content={content} >
                   <Button
                     style={{cursor: "auto"}}
                     type="primary"
                     size={size}
                     onClick={getUser}
                   >欢迎，{username}
                   </Button>
                 </Popover>
               {/*</Link>*/}
             </ButtonWrapper>
            </RightWrapper>:
            <RightWrapper>
              <ButtonWrapper>
                <Link to='/login'>
                  <Button type="primary" size={size}>
                    <Icon type="login" />
                    登录
                  </Button>
                </Link>
              </ButtonWrapper>
            </RightWrapper>
        }
      </Menu>
    );
  }
  //刷新页面发送cookie更新登录状态
  UNSAFE_componentWillMount() {
    this.props.getUser();
  }
}

const mapStateToProps = (state) => {
  return {
    userType: state.getIn(['login','user_permission']),
    login: state.getIn(['login','login']),
    username: state.getIn(['login','username']),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout() {
      dispatch(loginActionCreators.logout0())
    },
    getUser() {
      dispatch(loginActionCreators.getUser())
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header2);
