import axios from 'axios';
import * as constants from './constants';
import { message } from "antd";

axios.defaults.withCredentials = true;

const changeApplyData = (result) => ({
  type: constants.CHANGE_APPLY_DATA,
  myApplyList: result,
});

export const getMyApply = (u_id, page_size, page) => {
  return (dispatch) => {
    console.log(u_id);
    axios.get(
      '//simei-api.newitd.com/v1/application?apply_user_id='+u_id,{
        params: {
          page_size: page_size,
          page: page,
        }
      }
    ).then((res) => {
      console.log("获取个人申请列表成功！");
      const result = res.data.data.data;
      dispatch(changeApplyData(result));
      console.log(page_size, page);
    }).catch(() => {
      console.log(u_id);
    })
  }
}

export const deleteMyApply = (a_id) => {
  return (dispatch) => {
    axios.delete(
      '//simei-api.newitd.com/v1/application/'+a_id,
    ).then((res) => {
      console.log("删除申请成功！申请id：",a_id);
      message.success('撤销成功，请刷新当前页面');
    }).catch((error) => {
      console.log(error);
    })
  }
}
