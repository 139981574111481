import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoginWrapper, LoginBox, LoginTitle, CInputsWrapper, ContentInputWrapper, Content, InputWrapper, ButtonsWrapper, ButtonWrapper, BackGroundTitle, BGContent, BGVersion } from './style0';
import { actionCreators as loginActionCreators, actionCreators } from './store'

import { Input, Button, Tooltip } from 'antd';

const text = <span>请联系管理员注册！</span>;

class Login extends PureComponent {
  state = {
    loadings: [],
  };

  enterLoading = index => {
    const newLoadings = [...this.state.loadings];
    newLoadings[index] = true;
    this.setState({
      loadings: newLoadings,
    });
    setTimeout(() => {
      newLoadings[index] = false;
      this.setState({ loadings: newLoadings });
    }, 6000);
  };

  handleDefaultValue() {
    this.setState({
      account: '',
      password: ''
    })
  }

  handleChangeName(e) {
    console.log(e.target.value);
    this.setState({
      account: e.target.value
    })
  }

  handleChangePwd(e) {
    console.log(e.target.value);
    this.setState({
      password: e.target.value
    })
  }

  render() {
    const { loginStatus, getUser, permissionStatus } = this.props;
    const { loadings } = this.state;
    //console.log(loginStatus);

    if(!loginStatus) {
      return (
        <LoginWrapper>
          <BackGroundTitle>
            <BGContent>思美通信业务管理平台</BGContent>
            <BGVersion>Version 1.9.2</BGVersion>
          </BackGroundTitle>
          <LoginBox>
            <LoginTitle>员工登录</LoginTitle>
            {/*<Input0 placeholder='账号' ref={(input) => {this.account = input}}/>*/}
            {/*<Input0 placeholder='密码' type='password' ref={(input) => {this.password = input}}/>*/}
            <CInputsWrapper>
              <ContentInputWrapper><Content>用户名</Content><InputWrapper><Input onChange={this.handleChangeName.bind(this)}/></InputWrapper></ContentInputWrapper>
              <ContentInputWrapper><Content>密&emsp;码</Content><InputWrapper><Input.Password onChange={this.handleChangePwd.bind(this)}/></InputWrapper></ContentInputWrapper>
            </CInputsWrapper>
            <ButtonsWrapper>
              <ButtonWrapper><Tooltip placement="topRight" title={text}><Button type="primary">注册</Button></Tooltip></ButtonWrapper>
              <ButtonWrapper><Button type="primary" loading={loadings[0]} onClick={ () => this.handleLogin(this.state.account, this.state.password)}>登录</Button></ButtonWrapper>
            </ButtonsWrapper>
          </LoginBox>
        </LoginWrapper>
      )
    }else if(permissionStatus === 1){
      //若是管理员，跳转到审核页面，并更新
      return (
        <Redirect to='/apply_check'>{ getUser() }</Redirect>
      )
    }else{
      //不是管理员，则跳转到申请页面
      return (
        <Redirect to='/apply_submit'>{ getUser() }</Redirect>
      )
    }
  }

  componentDidMount() {
    this.handleDefaultValue();
  }

  handleLogin(account, password){
    if(account === '' && password === ''){
      alert("用户名和密码不能为空！");
    }else if(account === '') {
      alert("用户名不能为空！");
    }else if(password === '') {
      alert("密码不能为空！");
    }else{
      this.props.login(account, password);
    }
  }
}

const mapState = (state) => ({
  loginStatus: state.getIn(['login','login']),
  permissionStatus: state.getIn(['login','user_permission'])
});

const mapDispatch = (dispatch) => ({
  // login(accountElem, passwordElem){
  //   dispatch(actionCreators.login(accountElem.value, passwordElem.value));
  // },
  login(accountElem, passwordElem){
    dispatch(actionCreators.login(accountElem, passwordElem));
  },
  getUser() {
    dispatch(loginActionCreators.getUser())
  }
});


export default connect(mapState, mapDispatch)(Login);
