import * as constants from './constants';
import axios from 'axios';
axios.defaults.withCredentials = true;

export const logout = () => ({
  type: constants.LOGOUT,
  value: false
});

export const getUserLogin = (user) => ({
  type: constants.GET_USER_LOGIN,
  value: true,
  user_id: user.user_id,
  username: user.nickname,
  permission: user.permission,
})

export const modifyUserInfo = (user_id, nickname, permission) => {
  return (dispatch) => {
    axios.put('//simei-api.newitd.com/v1/user/'+user_id,{
      id: user_id,
      nickname: nickname,
      permission: permission,
    }).then((res) => {
      alert('修改成功！新的用户资料为：'+user_id+'，'+nickname+'，'+permission)
    }).catch((error) => {
      alert(error);
    })
  }
};

export const logout0 = () => {
  return (dispatch) => {
    axios.delete('http://simei-api.newitd.com/v1/session'
    ).then((res) => {
      dispatch(logout());
    }).catch((error) => {
      console.log(error.response.data);
      alert(error.response.data.msg);
    })
  }
};

export const getUser = () => {
  return (dispatch) => {
    axios.get("http://simei-api.newitd.com/v1/session")
      .then((res) => {
        const result = res.data.data;
        console.log(result.user_id);
        dispatch(getUserLogin(result));
      })
  }
};
