import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import {Table, Button, Breadcrumb, Form, Input, Layout, Radio, Modal} from 'antd';

import { actionCreators } from './store';
import reqwest from "reqwest";

const { Content, Footer } = Layout;

const columns = [
  {
    title: '账号',
    dataIndex: 'id',
    key: 'id',
    render: text => <a href>{text}</a>,
  },
  {
    title: '用户',
    dataIndex: 'nickname',
    key: 'nickname',
  },
  {
    title: '权限',
    dataIndex: 'permission',
    key: 'permission',
  },
];
const data = [];

let CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state={
        inputUserId: '',
        inputUserName: ''
      }
    }
    handleIdChange(e) {
      this.setState({
        inputUserId: e
      })
    }
    handleNameChange(e) {
      this.setState({
        inputUserName: e
      })
    }
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;

        return (
          <Modal
            visible={visible}
            title="添加用户"
            okText="Create"
            onCancel={onCancel}
            onOk={onCreate}
          >
            <Form layout="vertical">
              <Form.Item label="用户名">
                {getFieldDecorator('title', {
                  rules: [{required: true, message: '必须输入用户名!'}],
                })(<Input onChange={this.handleIdChange.bind(this)}/>)}
              </Form.Item>
              <Form.Item label="姓名">
                {getFieldDecorator('description', {
                  rules: [{required: true, message: '必须输入姓名!'}],
                })(<Input type="textarea" onChange={this.handleNameChange.bind(this)}/>)}
              </Form.Item>
              <Form.Item className="collection-create-form_last-form-item">
                {getFieldDecorator('modifier', {
                  initialValue: 'public',
                })(
                  <Radio.Group>
                    <Radio value="public">管理员</Radio>
                    <Radio value="private">普通用户</Radio>
                  </Radio.Group>,
                )}
              </Form.Item>
            </Form>
          </Modal>
        );
    }
  },
);

class CollectionsPage extends React.Component {
  state = {
    visible: false,
  };



  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    const { creatUser } = this.props.creatUser;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
//values.title 第一个input；values.description 第二个input

      console.log('Received values of form: ', values);
      creatUser(values.title, values.description);

      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    return (
      <div>
        <Link to='user_creator'>
        <Button type="primary" onClick={this.showModal}
        >
          添加用户
        </Button></Link>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
        />
      </div>
    );
  }

  handleCreateX() {

  }
}

class userManager extends React.Component{
  state = {
    data: [],
    value: 2,
    pagination: {},
    loading: false,
  };

  componentDidMount() {
    console.log("Did mount!");
    this.fetch();
  }

  handleTableChange = (pagination,filters, sorter) => {
    console.log("Table Changed!");
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      page_size: pagination.pageSize,
      page: pagination.current,
    });
  };

  fetch = (params = {}) => {
    console.log('params:', params);
    this.setState({ loading: true });
    reqwest({
      url: '//simei-api.newitd.com/v1/user',
      method: 'get',
      data: {
        page_size: 10,
        ...params,
      },
      type: 'json',
      withCredentials: true,
    }).then(res => {
      const pagination = { ...this.state.pagination };
      // Read total count from server
      // pagination.total = data.totalCount;
      pagination.total = res.data.meta.count;
      this.setState({
        loading: false,
        data: res.data.data,
        pagination,
      });
      console.log(this.state.data);
    });
  };

  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };
  render() {
    data.splice(0,data.length);

    if(this.props.permissionStatus === 1) {
      return (
        <Layout>
          <Content style={{ padding: '0 50px', marginTop: 8 }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>用户管理</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ background: '#fff', padding: 6, minHeight: 790 }}>
                {/*{*/}
                {/*  // eslint-disable-next-line array-callback-return*/}
                {/*  apply_list.map((item) => {*/}
                {/*    data.push({*/}
                {/*      username: item.get('apply_user_nickname'),*/}
                {/*      key: item.get('key'),*/}
                {/*      name: item.get('name'),*/}
                {/*      money: item.get('money')/100,*/}
                {/*      apply_time: item.get('apply_time'),*/}
                {/*      remark: item.get('remark'),*/}
                {/*      status: item.get('status'),*/}
                {/*      tags: ['通过', '不通过'],*/}
                {/*      id: item.get('id'),*/}
                {/*      nickname: item.get('nickname'),*/}
                {/*      permission: item.get('permission')*/}
                {/*    })*/}
                {/*  })}*/}
                <Table
                  rowKey={row=>row.id}
                  columns={columns}
                  dataSource={this.state.data}
                  pagination={this.state.pagination}
                  loading={this.state.loading}
                  onChange={this.handleTableChange}
                />
                <CollectionsPage />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Simei Manager ©2020</Footer>
        </Layout>
      );
    }else {
      return (
        <Redirect to='/'>{}</Redirect>
      )
    }
  }
}

const mapState = (state) => ({
  user_id: state.getIn(['login','user_id']),
  apply_list: state.getIn(['all_users','userList']),
  permissionStatus: state.getIn(['login','user_permission']),
});

const mapDispatch = (dispatch) => ({
  changeApplyList(id, apply_list) {
    apply_list.size === 0 && dispatch(actionCreators.getMyApply(id));
    console.log(apply_list.size)
  },
  creatUser(id, name){
    console.log("ok")
  }
});

export default connect(mapState, mapDispatch)(userManager);
