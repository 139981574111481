import styled from 'styled-components';
import logoPic from "../../statics/SMlogo.png";

export const LoginWrapper = styled.div`
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 48px;
  background: #F0F2F5;
`;

export const LoginBox = styled.div`
  width: 400px;
  height: 320px;
  margin: 160px auto;
  padding-top: 50px;
  background: #fff;
  box-shadow: 0 0 8px rgba(0,0,0,.1);
`;

export const Input = styled.input`
  display: block;
  width: 200px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  margin: 15px auto;
  color: #777;
`;

export const Button = styled.div`
  width: 200px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background: #1890FF;
  border-radius: 15px;
  margin: 30px auto;
  text-align: center;
`;

export const Logo = styled.div`
   
   top: 0;
   left: 0;
   margin-top: 32px;
   margin-left: 100px;
   margin-bottom: 32px;
   display: block;
   width: 200px;
   height: 60px
   background: url(${logoPic});
   background-size: contain;
`;