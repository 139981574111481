import {fromJS} from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
  allApplyList: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.CHANGE_ALL_APPLY_DATA:
      return state.merge({
        allApplyList: fromJS(action.myApplyList),
      })
    default:
      return state;
  }
}