import {fromJS} from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
  login: false,
  user_id: null,
  username: null,
  user_permission: null
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.CHANGE_LOGIN:
      return state.set('login', action.value);
    case constants.LOGOUT:
      return state.merge({
        login: action.value,
        user_id: null,
        username: null,
        user_permission: null,
      });
    case constants.GET_USER_LOGIN:
      return state.merge({
        login: action.value,
        user_id: action.user_id,
        username: action.username,
        user_permission: action.permission,
      });
    default:
      return state;
  }
}