import React from 'react';
import { connect } from 'react-redux';
// import { Redirect } from 'react-router-dom';
import { actionCreators as submitActionCreators } from './store';
import { Layout, Breadcrumb, Button  } from 'antd';
import { Form, Input, Upload, } from 'antd';
const { Content, Footer } = Layout;

class addPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: 'horizontal',
      inputValue1: '',
      inputValue2: '',
      inputValue3: '',
      inputValue4: this.props.user_id,
    };
  }

  handleChange1(e) {
    this.setState({
      inputValue1: e.target.value
    })
  }
  handleChange2(e) {
    this.setState({
      inputValue2: e.target.value
    })
  }
  handleChange3(e) {
    this.setState({
      inputValue3: e.target.value
    })
  }
  handleChange4(e) {
    this.setState({
      inputValue4: e.target.value
    })
  }
  handleChange5(e) {
    this.setState({
      inputValue3: e.target.value
    })
  }
  handleChange6(e) {
    this.setState({
      inputValue4: e.target.value
    })
  }

  handleFormLayoutChange = e => {
    this.setState({ formLayout: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        console.log('表单验证失败');
      }else{
        // this.handleBtnClick(this.state.inputValue1,this.state.inputValue2,this.state.inputValue3,this.state.inputValue4);
        console.log(values);
      }
    });
  };


  render() {
    const { user_id } = this.props;

    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === 'horizontal'
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 6 },
        }
        : null;
    const buttonItemLayout =
      formLayout === 'horizontal'
        ? {
          wrapperCol: { span: 14, offset: 4 },
        }
        : null;

    // const normFile = e => {
    //   console.log('Upload event:', e);
    //   if (Array.isArray(e)) {
    //     return e;
    //   }
    //   return e && e.fileList;
    // };

      return (
        <Layout>
          <Content style={{ padding: '0 50px', marginTop: 8 }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>添加客户</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ background: '#fff', padding: 24, minHeight: 790 }}>

              <Form style={{marginTop: 20}} layout={formLayout} onSubmit={this.handleSubmit}>
                <Form.Item name="principle" label="收款人" {...formItemLayout}>
                  {getFieldDecorator('principle_input', {
                    initialValue: user_id,
                    rules: [
                      { required: true, message: '负责人id不能为空'}
                    ],
                  })
                    // eslint-disable-next-line no-unexpected-multiline
                    (<Input onChange={this.handleChange4.bind(this)} />)}
                </Form.Item>
                <Form.Item name="input1" label="收款类型" {...formItemLayout}>
                  {getFieldDecorator('mark_input1', {
                    rules: [{ required: true, message: '客户名称不能为空'}],
                  })
                   // eslint-disable-next-line no-unexpected-multiline
                  (<Input onChange={this.handleChange1.bind(this)} />)}
                </Form.Item>
                <Form.Item name="input2" label="金额" {...formItemLayout}>
                  {getFieldDecorator('mark_input2', {
                    rules: [{ required: true, message: '联系电话不能为空'}],
                  })
                   // eslint-disable-next-line no-unexpected-multiline
                  (<Input onChange={this.handleChange2.bind(this)} />)}
                </Form.Item>
                <Form.Item name="input3" label="备注" {...formItemLayout}>
                  {getFieldDecorator('mark_input3', {
                    rules: [{ required: true, message: '地址不能为空'}],
                  })
                   // eslint-disable-next-line no-unexpected-multiline
                  (<Input onChange={this.handleChange3.bind(this)} />)}
                </Form.Item>
                <Form.Item name="input4" label="金额" {...formItemLayout}>
                  {getFieldDecorator('mark_input4', {
                    rules: [{ required: true, message: '地址不能为空'}],
                  })
                    // eslint-disable-next-line no-unexpected-multiline
                    (<Input onChange={this.handleChange5.bind(this)} />)}
                </Form.Item>
                <Form.Item name="input5" label="客户id" {...formItemLayout}>
                  {getFieldDecorator('mark_input5', {
                    rules: [{ required: true, message: '地址不能为空'}],
                  })
                    // eslint-disable-next-line no-unexpected-multiline
                    (<Input onChange={this.handleChange6.bind(this)} />)}
                </Form.Item>
                <Form.Item label="Upload" extra="longgggggggggggggggggggggggggggggggggg" {...formItemLayout}>
                  {getFieldDecorator('upload', {
                    valuePropName: 'fileList1',
                    getValueFromEvent: this.normFile,
                  })(
                    <Upload file="files"
                            action="https://simei-api.newitd.com/v1/file"
                            withCredentials={true}
                            listType="picture">
                      <Button>
                        上传
                      </Button>
                    </Upload>,
                  )}
                </Form.Item>

                <Form.Item {...buttonItemLayout}>
                  <Button type="primary" htmlType="submit" >提交</Button>
                  <Button style={{marginLeft: '10px'}} htmlType="button" onClick={() => this.handleBtnReset()}>清空</Button>
                </Form.Item>
              </Form>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
        </Layout>
      );

  }
  handleBtnClick(input1, input2, input3, input4) {
    this.props.submit(input1, input2, input3, input4);
    this.props.form.resetFields();
  }
  handleBtnReset() {
    this.props.form.resetFields();
  }
}

const mapState = (state) => ({
  loginStatus: state.getIn(['login','login']),
  user_id: state.getIn(['login','user_id'])
});

const mapDispatch = (dispatch) => ({
  submit(input1, input2, input3, input4) {
    dispatch(submitActionCreators.submitApply(input1, input2, input3, input4));
  }
});

const WrappedDemo = Form.create({ name: 'validate_other' })(addPayment);
export default connect(mapState, mapDispatch)(WrappedDemo);
