import {fromJS} from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
  total_fund: 0,
  input_value: 0,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.CHANGE_FUND:
      return state.set('total_fund', action.total_fund);
    case constants.CHANGE_INPUT_VALUE:
      return state.set('input_value',action.value);
    default:
      return state;
  }
}