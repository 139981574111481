import React from 'react';
import { connect } from 'react-redux';
import { actionCreators } from './store';
import { Table, Breadcrumb, Layout } from 'antd';
import reqwest from 'reqwest';
const { Content, Footer } = Layout;

const columns = [
  {
    title: '操作人',
    dataIndex: 'user.nickname',
    key: 'operator_name',
    width: '20%',
    align: 'center',
  },
  {
    title: '变更资金',
    dataIndex: 'money'/100,
    key: 'money_change',
    width: '40%',
    align: 'center',
    render: (apply_id, v) => (
      <span>
            {
              v.money > 0 ?
                <span>+{v.money / 100}</span>:
                <span>-{v.money / 100}</span>
            }
          </span>
    ),
  },
  {
    title: '操作时间',
    dataIndex: 'create_time',
    key: 'operate_time',
    width: '40%',
    align: 'center',
  },
];
const data = [];

class addRecords extends React.Component{
  state = {
    data: [],
    value: 2,
    pagination: {},
    loading: false,
  };

  componentDidMount() {
    console.log("Did mount!");
    this.fetch();
  }

  handleTableChange = (pagination,filters, sorter) => {
    console.log("Table Changed!");
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      page_size: pagination.pageSize,
      page: pagination.current,
    });
  };

  fetch = (params = {}) => {
    console.log('params:', params);
    this.setState({ loading: true });
    reqwest({
      url: '//simei-api.newitd.com/v1/fund',
      method: 'get',
      data: {
        page_size: 10,
        ...params,
      },
      type: 'json',
      withCredentials: true,
    }).then(res => {
      const pagination = { ...this.state.pagination };
      // Read total count from server
      // pagination.total = data.totalCount;
      pagination.total = res.data.meta.count;
      this.setState({
        loading: false,
        data: res.data.data,
        pagination,
      });
      console.log(this.state.data);
    });
  };

  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };
  render() {
    data.splice(0,data.length);

    /*if(this.props.permissionStatus === 1){*/
      return (
        <Layout>
          <Content style={{ padding: '0 50px', marginTop: 8 }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>操作记录</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ background: '#fff', padding: 6, minHeight: 790 }}>
              <Table
                scroll={{ x:400 }}
                columns={columns}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Simei Manager ©2020</Footer>
        </Layout>
      );
    /*}else{
      return (
        <Redirect to='/'>{}</Redirect>
      )
    }*/
  }
}

const mapState = (state) => ({
  fund_list: state.getIn(['fund_records','allFundList']),
});

const mapDispatch = (dispatch) => ({
  changeApplyList(id, fund_list) {
    fund_list.size === 0 && dispatch(actionCreators.getAllApply(id))
  }
});

export default connect(mapState, mapDispatch)(addRecords);
