import axios from 'axios';
import * as constants from './constants';
import { message } from "antd";

axios.defaults.withCredentials = true;

const changeApplyData = (result) => ({
  type: constants.CHANGE_ALL_APPLY_DATA,
  myApplyList: result,
})

export const getAllApply = (id) => {
  return (dispatch) => {
    axios.get(
      '//simei-api.newitd.com/v1/application',
    ).then((res) => {
      console.log("获取列表成功！");
      const result = res.data.data.data;
      dispatch(changeApplyData(result));
      console.log(result);
    }).catch(() => {
      console.log(id)
    })
  }
}

export const changeCheckStatus = (a_id, new_statue) => {
  return (dispatch) => {
    axios.put(
      '//simei-api.newitd.com/v1/application/'+a_id,
      {
        status: new_statue
      }
    ).then((res) => {
      message.success("更改成功！当前状态："+(new_statue? "通过":"待审核"))
      console.log("更改审核状态成功！当前状态：",new_statue);
    }).catch((error) => {
      message.error("操作失败："+error)
    })
  }
}
