import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { LoginWrapper, LoginBox, Input, Button } from './style';
import {actionCreators as loginActionCreators, actionCreators} from './store'

class userCreator extends PureComponent {
  render() {
      return (
        <LoginWrapper>
          <LoginBox>
            <Input placeholder='用户id' ref={(input) => {this.user_id = input}}/>
            <Input placeholder='姓名'  ref={(input) => {this.nickname = input}}/>
            <Input placeholder='权限：0—非管理，1—管理'  ref={(input) => {this.permission = input}}/>
            <Button onClick={() => this.props.modifyUserInfo(this.user_id, this.nickname, this.permission)}>提交修改</Button>
          </LoginBox>
        </LoginWrapper>
      )
    }
}

const mapState = (state) => ({
  loginStatus: state.getIn(['login','login'])
});

const mapDispatch = (dispatch) => ({
  modifyUserInfo(user_idElem, nicknameElem, permissionElem){
    dispatch(actionCreators.modifyUserInfo(user_idElem.value, nicknameElem.value, permissionElem.value));
  },
  getUser() {
    dispatch(loginActionCreators.getUser())
  }
});

export default connect(mapState, mapDispatch)(userCreator);