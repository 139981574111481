import axios from 'axios';
import * as constants from './constants';
axios.defaults.withCredentials = true;

const changeApplyData = (result) => ({
  type: constants.CHANGE_ALL_APPLY_DATA,
  myApplyList: result,
});

export const getAllApply = (page_size, page) => {
  return (dispatch) => {
    axios.get(
      '//simei-api.newitd.com/v1/application',{
        params: {
          page_size: page_size,
          page: page,
        }
      }
    ).then((res) => {
      console.log("获取列表成功！");
      const result = res.data.data.data;
      dispatch(changeApplyData(result));
      console.log(result);
    }).catch(() => {
      console.log(page_size, page);
    })
  }
};

export const changeCheckStatus = (a_id, new_statue) => {
  return (dispatch) => {
    axios.put(
      'http://simei-api.newitd.com/v1/application/'+a_id,
      {
        status: new_statue
      }
    ).then((res) => {
      console.log("更改审核状态成功！当前状态：",{new_statue});
    }).catch((error) => {
      console.log("操作失败!")
    })
  }
};
