import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { LoginWrapper, LoginBox, Input, Button } from './style';
import { actionCreators } from './store'

class customerDelete extends PureComponent {
  render() {
      return (
        <LoginWrapper>
          <LoginBox>
            <Input placeholder='客户id' ref={(input) => {this.id = input}}/>
            <Button onClick={() => this.props.modifyUserInfo(this.id)}>提交修改</Button>
          </LoginBox>
        </LoginWrapper>
      )
    }
}

const mapState = (state) => ({
  loginStatus: state.getIn(['login','login'])
});

const mapDispatch = (dispatch) => ({
  modifyUserInfo(idElem){
    dispatch(actionCreators.modifyUserInfo(idElem.value));
  },
});

export default connect(mapState, mapDispatch)(customerDelete);
