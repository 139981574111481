import * as constants from './constants';
import axios from 'axios';
axios.defaults.withCredentials = true;

export const getFund = (total_fund) => ({
  type: constants.CHANGE_FUND,
  total_fund: total_fund
});

export const changeInput = (total_fund) => ({
  type: constants.CHANGE_INPUT_VALUE,
  value: total_fund
});

export const getTotalFund = () => {
  return (dispatch) => {
    axios.get("//simei-api.newitd.com/v1/fund/current")
      .then((res) => {
        const result = res.data.data;
        dispatch(getFund(result));
      }).catch((error) => {
        console.log(error);
      }
    )
  }
};

export const addToFund = (money_add) => {
  return (dispatch) => {
    axios.post("//simei-api.newitd.com/v1/fund", {
      money: money_add,
    })
      .then((res) => {
        alert("添加资金成功！");
      }).catch((error) => {
      console.log(error.response.data.msg);
    })
  }
};

export const changeInputMoney = (input_value) => {
  return (dispatch) => {
    console.log(input_value);
    dispatch(changeInput(input_value));
  }
};


