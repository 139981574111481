import { combineReducers } from 'redux-immutable';
import { reducer as headerReducer } from '../common/header/store';
import { reducer as homeReducer }  from '../pages/home/store';
import { reducer as detailReducer } from '../pages/detail/store';
import { reducer as loginReducer } from '../pages/login/store';
import { reducer as testReducer } from '../pages/test/store';
import { reducer as fundReducer } from '../pages/fundadd/store';
import { reducer as myApplyReducer } from '../pages/applyrecords/store';
import { reducer as usersInfoReducer } from '../pages/usermanager/store' ;
import { reducer as allApplyReducer } from '../pages/applycheck/store';
import { reducer as fundAddReducer } from '../pages/fundrecords/store';
import { reducer as allCheckedReducer } from '../pages/checkrecords/store'

import { reducer as customerReducer } from '../pages/customermanager/store';

const reducer = combineReducers({
  header: headerReducer,
  home: homeReducer,
  detail:detailReducer,
  login: loginReducer,
  test: testReducer,
  fund: fundReducer,
  my_apply: myApplyReducer,
  all_users: usersInfoReducer,
  all_apply: allApplyReducer,
  fund_records: fundAddReducer,
  check_records: allCheckedReducer,

  all_customer: customerReducer,
});

export default reducer;
