import * as constants from './constants';
import axios from 'axios';
axios.defaults.withCredentials = true;

export const logout = () => ({
  type: constants.LOGOUT,
  value: false
});

export const getUserLogin = (user) => ({
  type: constants.GET_USER_LOGIN,
  value: true,
  user_id: user.user_id,
  username: user.nickname,
  permission: user.permission,
})

export const modifyUserInfo = (id) => {
  return (dispatch) => {
    axios.delete('//simei-api.newitd.com/v1/customer/'+id,{
    }).then((res) => {
      alert('删除用户成功！');
    }).catch((error) => {
      alert(error);
    })
  }
};

export const getUser = () => {
  return (dispatch) => {
    axios.get("//simei-api.newitd.com/v1/session")
      .then((res) => {
        const result = res.data.data;
        console.log(result.user_id);
        dispatch(getUserLogin(result));
      })
  }
};
