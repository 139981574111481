import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route} from "react-router-dom";
import Header2 from './common/header2'
import Login from './pages/login';
import Test from './pages/test';
import store from './store';

import SiMeiHome from './pages/smhome';
import applySubmit from './pages/applysubmit';
import applyRecords from './pages/applyrecords';
import applyCheck from './pages/applycheck';
import checkRecords from './pages/checkrecords';

import fundAdd from './pages/fundadd';
import fundDelete from './pages/fundadd';

import addCustomer from './pages/addcustomer';
import customerManager from './pages/customermanager';
import customerModify from './pages/customermodify';
import customerDelete from './pages/customerdelete';

import userCreator from './pages/usercreator';
import userManager from './pages/usermanager';
import userModify from './pages/usermodify';
import passwordModify from './pages/passwordmodify';
import addRecords from './pages/fundrecords';

import addPayment from './pages/addpayment';

import buildPage from "./pages/default/buildpage";

import { GlobalStyle } from "./style";
import { GlobalStyleFont } from "./statics/iconfont/iconfont";



class App extends Component {
  render() {
    return (
          <Provider store={store}>
            <GlobalStyle/>
            <GlobalStyleFont/>
            <BrowserRouter>
              <div>
                <Header2 />
                <Route path='/' exact component={SiMeiHome} />
                <Route path='/login' exact component={Login} />
                <Route path='/apply_submit' exact component={applySubmit} />
                <Route path='/apply_records' exact component={applyRecords} />
                <Route path='/fund_add' exact component={fundAdd} />
                <Route path='/apply_check' exact component={applyCheck} />
                <Route path='/user_manager' exact component={userManager} />
                <Route path='/test' exact component={Test} />
                <Route path='/user_creator' exact component={userCreator} />
                <Route path='/user_modify' exact component={userModify} />
                <Route path='/change_password' exact component={passwordModify} />
                <Route path='/check_records' exact component={checkRecords} />
                <Route path='/fund_records' exact component={addRecords} />
                <Route path='/fund_delete' exact component={fundDelete} />

                <Route path='/customer_add' exact component={addCustomer} />
                <Route path='/customer_manager' exact component={customerManager} />
                <Route path='/customer_modify' exact component={customerModify} />
                <Route path='/customer_delete' exact component={customerDelete} />

                <Route path='/payment_add' exact component={addPayment} />

                <Route path='/building' exact component={buildPage} />
              </div>
            </BrowserRouter>
          </Provider>
    );
  }
}

export default App;
