import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { actionCreators as submitActionCreators } from './store';
import { Layout, Breadcrumb, Button, InputNumber } from 'antd';
import { Form, Input, Select } from 'antd';
const { Content, Footer } = Layout;

class applySubmit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: 'horizontal',
      inputValue1: '',
      inputValue2: '',
      inputValue3: ''
    };
  }

  handleChange1(e) {
    this.setState({
      inputValue1: e
    })
  }
  handleChange2(e) {
    this.setState({
      inputValue2: e
    })
  }
  handleChange3(e) {
    this.setState({
      inputValue3: e.target.value
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err) => {
      //如果验证错误
      if (err) {
        console.log('表单验证失败');
      }else {
        this.handleBtnClick(this.state.inputValue1,parseFloat(this.state.inputValue2),this.state.inputValue3);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === 'horizontal'
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 6 },
        }
        : null;
    const buttonItemLayout =
      formLayout === 'horizontal'
        ? {
          wrapperCol: { span: 14, offset: 4 },
        }
        : null;
    if(this.props.loginStatus){
      return (
        <Layout>
          <Content style={{ padding: '0 50px', marginTop: 8 }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>提交申请</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ background: '#fff', padding: 24, minHeight: 790 }}>
              <Form style={{ marginTop: 20 }} layout={formLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="申请理由" hasFeedback {...formItemLayout}>
                  {getFieldDecorator('select', {
                    rules: [{ required: true, message: '请选择一个类型!' }],
                  })(
                    <Select setFieldsValue={this.state.inputValue1} placeholder="选择一个类型..." onChange={this.handleChange1.bind(this)}>
                      <Option value="宽带缴费">宽带缴费</Option>
                      <Option value="宽带押金">宽带押金</Option>
                      <Option value="话费充值">话费充值</Option>
                      <Option value="君宝加钱">君宝加钱</Option>
                      <Option value="虚拟卡佣金">虚拟卡佣金</Option>
                      <Option value="交通费">交通费</Option>
                      <Option value="快递费">快递费</Option>
                      <Option value="发票">发票</Option>
                      <Option value="其他">其他</Option>
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="金额(元)" {...formItemLayout}>
                  {getFieldDecorator('num_input', {
                    rules: [{ required: true, message: '请填写数字！'}],
                  })
                  // eslint-disable-next-line no-unexpected-multiline
                  (<InputNumber min={1} max={200000} step={10} onChange={this.handleChange2.bind(this)} />)}
                </Form.Item>
                <Form.Item label="备注" {...formItemLayout}>
                  {getFieldDecorator('mark_input', {
                    rules: [{ required: true, message: '备注不能为空！'}],
                  })
                  // eslint-disable-next-line no-unexpected-multiline
                  (<Input onChange={this.handleChange3.bind(this)} />)}
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                  <Button type="primary" htmlType="submit">提交</Button>
                  <Button style={{marginLeft: '10px'}} htmlType="button" onClick={() => this.handleBtnReset()}>清空</Button>
                </Form.Item>
              </Form>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Simei Manager ©2020</Footer>
        </Layout>

      );
    }else {
      return (
        <Redirect to='/'/>
      )
    }
  }
  handleBtnClick(input1,input2,input3) {
    this.props.submit(input1,input2,input3);
    this.props.form.resetFields();
  }
  handleBtnReset() {
    this.props.form.resetFields();
  }
}

const mapState = (state) => ({
  loginStatus: state.getIn(['login','login'])
});

const mapDispatch = (dispatch) => ({
  submit(input1, input2, input3) {
    dispatch(submitActionCreators.submitApply(input1, input2, input3));
  }
});

const WrappedDemo = Form.create({ name: 'validate_other' })(applySubmit);
export default connect(mapState, mapDispatch)(WrappedDemo);
