import axios from 'axios';
import { message } from "antd";

axios.defaults.withCredentials = true;

export const submitApply = (name_input, phone_input, address_input, principal_input) => {
  return (dispatch) => {
    axios.post(
      "//simei-api.newitd.com/v1/customer",
      {
        name: name_input,
        phone: phone_input,
        address: address_input,
        principal_user_id: principal_input
      })
      .then((res) => {
        message.success('添加客户成功');
      }).catch((error) => {
        message.error("添加客户失败");
        console.log(error);
    })
  }
};
