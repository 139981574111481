import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { LoginWrapper, LoginBox, Input, Button } from './style';
import {actionCreators as loginActionCreators, actionCreators} from './store'

class userCreator extends PureComponent {
  render() {

      return (
        <LoginWrapper>
          <LoginBox>
            <Input placeholder='用户名' ref={(input) => {this.account = input}}/>
            <Input placeholder='姓名'  ref={(input) => {this.password = input}}/>
            <Button onClick={() => this.props.creatOneUser(this.account, this.password)}>创建</Button>
          </LoginBox>
        </LoginWrapper>
      )

    }
}

const mapState = (state) => ({
  loginStatus: state.getIn(['login','login'])
});

const mapDispatch = (dispatch) => ({
  creatOneUser(accountElem, passwordElem){
    dispatch(actionCreators.creatOneUser(accountElem.value, passwordElem.value));
  },
  getUser() {
    dispatch(loginActionCreators.getUser())
  }
});

export default connect(mapState, mapDispatch)(userCreator);