import axios from 'axios';
import * as constants from './constants';

const changeApplyData = (result) => ({
  type: constants.CHANGE_USERS_DATA,
  myApplyList: result,
});

export const getMyApply = (page_size, page) => {
  return (dispatch) => {
    axios.get(
      '//simei-api.newitd.com/v1/user',{
        params: {
          page_size: page_size,
          page: page,
        }
      }
    ).then((res) => {
      console.log("获取用户列表成功！");
      const result = res.data.data.data;
      dispatch(changeApplyData(result));
      console.log(result);
    }).catch(() => {
      console.log(page_size, page);
    })
  }
};

// export const creatOneUser = (user_id, user_name) => {
//   return (dispatch) => {
//     axios.post("http://simei-api.newitd.com/v1/user", {
//       id: user_id,
//       nickname: user_name
//     })
//       .then((res) => {
//         alert("添加一个新用户成功！")
//       })
//   }
// };
