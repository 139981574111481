import * as constants from './constants';
import axios from 'axios';
axios.defaults.withCredentials = true;

const changeLogin = () => ({
  type: constants.CHANGE_LOGIN,
  value: true
});

export const logout = () => ({
  type: constants.LOGOUT,
  value: false
});

export const getUserLogin = (user) => ({
  type: constants.GET_USER_LOGIN,
  value: true,
  user_id: user.id,
  username: user.nickname,
  permission: user.permission,
});

export const login = (account, password) => {
  return (dispatch) => {
    axios.post('//simei-api.newitd.com/v1/session',{
      id: account,
      password: password
    }).then((res) => {
      dispatch(changeLogin());
    }).catch((error) => {
      if(!error.response){
        alert("请检查网络连接！");
      }
      else if(error.response){
        alert("用户名或密码错误！");
      }
    })
  }
};

export const logout0 = () => {
  return (dispatch) => {
    axios.delete('//simei-api.newitd.com/v1/session'
    ).then((res) => {
      dispatch(logout());
    }).catch((error) => {
      console.log(error.response.data);
      alert(error.response.data.msg);
    })
  }
};

export const getUser = () => {
  return (dispatch) => {
    axios.get("//simei-api.newitd.com/v1/session")
      .then((res) => {
        const result = res.data.data;
        console.log(result.user_id);
        dispatch(getUserLogin(result));
      })
  }
};

