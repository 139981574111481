import * as constants from './constants';
import { message } from "antd";
import axios from 'axios';
axios.defaults.withCredentials = true;


export const logout = () => ({
  type: constants.LOGOUT,
  value: false
});

export const getUserLogin = (user) => ({
  type: constants.GET_USER_LOGIN,
  value: true,
  user_id: user.user_id,
  username: user.nickname,
  permission: user.permission,
})

export const login = (user_id, old_password, new_password, fun_reset) => {
  return (dispatch) => {
    axios.put('//simei-api.newitd.com/v1/user/'+user_id,{
      id: user_id,
      old_password: old_password,
      password: new_password,
    }).then((res) => {
      message.success('修改密码成功！');
      fun_reset();
    }).catch((error) => {
      message.error('修改失败，旧密码有误！');
    })
  }
};

export const getUser = () => {
  return (dispatch) => {
    axios.post("//simei-api.newitd.com/v1/user/get_login_user_info")
      .then((res) => {
        const result = res.data.data;
        console.log(result.user_id);
        dispatch(getUserLogin(result));
      })
  }
};

