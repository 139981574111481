import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Breadcrumb, Layout, Modal ,Tag, Icon } from 'antd';
import { actionCreators } from './store';
import reqwest from "reqwest";
const { Content, Footer } = Layout;
const { confirm } = Modal;


const data = [];

class applyRecords extends React.Component{
  state = {
    user_id: 'simei000',
    data: [],
    value: 2,
    pagination: {},
    loading: false,
  };

  componentDidMount() {
    console.log("Did mount!");
    this.setState((state, props)=>({
      user_id: props.user_id,
    }));
    this.fetch();
  }

  handleTableChange = (pagination,filters, sorter) => {
    console.log("Table Changed!");
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      apply_user_id: this.props.user_id,
      page_size: pagination.pageSize,
      page: pagination.current,
    });
  };

  fetch = (params = {},id) => {
    console.log('params:', params);
    this.setState({ loading: true });
    reqwest({
      url: '//simei-api.newitd.com/v1/application?apply_user_id='+this.props.user_id,
      method: 'get',
      data: {
        page_size: 10,
        ...params,
      },
      type: 'json',
      withCredentials: true,
    }).then(res => {
      const pagination = { ...this.state.pagination };
      // Read total count from server
      // pagination.total = data.totalCount;
      pagination.total = res.data.meta.count;
      this.setState({
        loading: false,
        data: res.data.data,
        pagination,
      });
      console.log(this.state.data);
    });
  };

  render() {
    const columns = [
      {
        title: '类型',
        dataIndex: 'name',
        key: '1',
        width: '120px'
      },
      {
        title: '金额',
        dataIndex: 'money',
        key: '2',
        width: '120px',
        render: (text, record, index) => (
          <span>
            <div>{record.money / 100}</div>
          </span>
        ),
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: '3',
        width: '420px'
      },
      {
        title: '申请时间',
        dataIndex: 'apply_time',
        key: '4',
      },
      {
        title: '审核情况',
        dataIndex: 'status',
        key: '5',
        width: '120px',
        render: (apply_id, v) => (
          <span>
            {
              v.status === 1 ?
                <Tag color="green">通过</Tag>:
                <Tag color="geekblue">待审核</Tag>
            }
          </span>
        ),
      },
      {
        title: '操作',
        key: 'action',
        dataIndex: 'apply_id',
        render: (apply_id, v) => (
          <span>
            {
              v.status === 0 ?
                <Button type="dashed" onClick={() => showConfirm(v.id,this.props.handleDeleteApply)}>撤回</Button>:
                <Button type="dashed" disabled onClick={() => showConfirm(v.id,this.props.handleDeleteApply)}>撤回</Button>}
          </span>
        ),
        width: '100px',
        fixed: 'right'
      },
    ];
    data.splice(0,data.length);

    /*if(this.props.loginStatus){*/
      return (
        <Layout>
          <Content style={{ padding: '0 50px', marginTop: 8 }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>我的申请<Icon style={{marginLeft: 5}} onClick={()=>this.fetch()} type="sync" spin={this.state.loading} /></Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ background: '#fff', padding: 6, minHeight: 790 }}>
              <Table
                rowKey={row=>row.id}
                scroll={{ x:1000 }}
                columns={columns}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Simei Manager ©2020</Footer>
        </Layout>
      );
    /*}else{
      return (
        <Redirect to='/'/>
      )
    }*/
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }
}
function showConfirm(id, f) {
  confirm({
    title: '确定要撤销这条申请吗?',
    content: '注意：撤销后无法恢复该申请。',
    okText: '确认',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      f(id);
      return new Promise((resolve, reject) => {
        setTimeout(Math.random() > 0.5 ? resolve : reject, 0);
      }).catch(() => console.log('Oops errors!'));
    },
    onCancel() {},
  });
}

const mapState = (state) => ({
  user_id: state.getIn(['login','user_id']),
  apply_list: state.getIn(['my_apply','applyList']),
  loginStatus: state.getIn(['login','login']),
});

const mapDispatch = (dispatch) => ({
  changeApplyList(id, apply_list) {
    apply_list.size === 0 && dispatch(actionCreators.getMyApply(id));
  },
  handleDeleteApply(apply_id) {
    dispatch(actionCreators.deleteMyApply(apply_id));
  }
});

export default connect(mapState, mapDispatch)(applyRecords);
