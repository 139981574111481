import React from 'react';
import { connect } from 'react-redux';
// import './style.css';
import { actionCreators } from './store';
import { Table, Breadcrumb, Layout, Tag } from 'antd';
import reqwest from 'reqwest';
const { Content, Footer } = Layout;

const columns = [
  /*{
    title: '次序',
    dataIndex: 'id',
    key: 'apply_id',
  },*/

  {
    title: '申请人',
    dataIndex: 'apply_user.nickname',
    key: 'apply_user_id',
    width: '100px',
    fixed: 'left'
  },
  {
    title: '类型',
    dataIndex: 'name',
    key: 'name',
    width: '110px'
  },
  {
    title: '金额',
    dataIndex: 'money',
    key: 'money',
    width: '80px',
    render: (apply_id, v) => (
      <span>
            {
              <div>{v.money / 100}</div>
            }
      </span>
    ),
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    // ellipsis: true,

  },
  {
    title: '申请时间',
    dataIndex: 'apply_time',
    key: 'apply_time',
    width: '200px',
    ellipsis: true,
  },
  {
    title: '审核时间',
    dataIndex: 'approval_time',
    key: 'approval_time',
    width: '200px',
    ellipsis: true,
  },
  {
    title: '审核人',
    dataIndex: 'approval_user.nickname',
    key: 'approval_user_id',
    width: '100px',
  },
  {
    title: '审核',
    dataIndex: 'status',
    key: 'status',
    width: '80px',
    render: (apply_id, status) => (
      <span>
            {
              status.status === 1 ?
              <Tag color="green">通过</Tag>:
              <Tag color="geekblue">待审核</Tag>
            }
      </span>
    ),
  },
];
const data = [];
class applyRecords extends React.Component{
  state = {
    data: [],
    value: 2,
    pagination: {},
    loading: false,
  };

  componentDidMount() {
    console.log("Did mount!");
    this.fetch();
  }

  handleTableChange = (pagination,filters, sorter) => {
    console.log("Table Changed!");
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      page_size: pagination.pageSize,
      page: pagination.current,
    });
  };

  fetch = (params = {}) => {
    console.log('params:', params);
    this.setState({ loading: true });
    reqwest({
      url: '//simei-api.newitd.com/v1/application',
      method: 'get',
      data: {
        page_size: 10,
        ...params,
      },
      type: 'json',
      withCredentials: true,
    }).then(res => {
      const pagination = { ...this.state.pagination };
      // Read total count from server
      // pagination.total = data.totalCount;
      pagination.total = res.data.meta.count;
      this.setState({
        loading: false,
        data: res.data.data,
        pagination,
      });
      console.log(this.state.data);
    });
  };

  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };
  render() {
    data.splice(0,data.length);

    /*if(this.props.permissionStatus === 1){*/
      return (
        <Layout>
          <Content style={{ padding: '0 50px', marginTop: 8 }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>审核记录</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ background: '#fff', padding: 6, minHeight: 790 }}>
              <Table
                rowKey={row=>row.apply_id}
                scroll={{ x: 1000 }}
                columns={columns}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Simei Manager ©2020</Footer>
        </Layout>
      );
    /*}else{
      return (
        <Redirect to='/'>{}</Redirect>
      )
    }*/
  }
}

const mapState = (state) => ({
  user_id: state.getIn(['login','user_id']),
  apply_list: state.getIn(['all_apply','allApplyList']),
  permissionStatus: state.getIn(['login','user_permission']),
});

const mapDispatch = (dispatch) => ({
  changeFundList(id, apply_list) {
    apply_list.size === 0 && dispatch(actionCreators.getAllApply(100,1))
  }
});

export default connect(mapState, mapDispatch)(applyRecords);
