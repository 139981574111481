import {fromJS} from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
  allFundList: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.CHANGE_ADD_FUND:
      return state.merge({
        allFundList: fromJS(action.addFundRecordList),
      })
    default:
      return state;
  }
}