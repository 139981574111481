import axios from 'axios';
import * as constants from './constants';
axios.defaults.withCredentials = true;

const changeAddFundData = (result) => ({
  type: constants.CHANGE_ADD_FUND,
  addFundRecordList: result,
});

export const getAllApply = (page_size, page) => {
  return (dispatch) => {
    axios.get(
      '//simei-api.newitd.com/v1/fund',{
        params: {
          page_size: page_size,
          page: page,
        }
      }
    ).then((res) => {
      console.log("获取资金日志成功！");
      const result = res.data.data.data;
      dispatch(changeAddFundData(result));
      console.log(result);
    }).catch(() => {
      console.log(page_size, page);
    })
  }
};
