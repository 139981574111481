import React, {Component} from "react";
import { B_Logo, VersionText } from "./style";
import {BackGroundWrapper} from "./style";

class SiMeiHome extends Component {

  render() {
    return (
      <BackGroundWrapper>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <B_Logo><VersionText></VersionText></B_Logo>
      </BackGroundWrapper>
    )
  }

}
export default SiMeiHome;