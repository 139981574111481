import styled from 'styled-components';

export const ItemWrapper = styled.div`
  width: 80px;
`;

export const ButtonWrapper = styled.div`
  float: right;
  margin-right: 10px;
  padding: 0 10px;
`;

export const RightWrapper = styled.div`
  float: right;
  padding: 0 10px;
`;

export const UserInfo = styled.div`  //个人信息框
  position: absolute;
  left: 0;
  top: 58px;
  width: 240px;
  padding: 0 20px;
  height: 100px;
  background: green;
  box-shadow: 0 0 8px rgba(0,0,0,.2);
`;
