import styled from 'styled-components';
import BG_Pic from "../../statics/login-bg2.jpg";
import logoPic from "../../statics/SMlogo.png";

export const LoginWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: center;
  
  z-index: 1;
  position:absolute; 
  top:0; 
  left:0; 
  height:100%; 
  width:100%; 
  background: url(${BG_Pic}) no-repeat center 0;
  background-size: cover;
  zoom: 1; 
`;

export const BackGroundTitle = styled.div`
  float: left;
  top: 50%;
`;

export const LoginBox = styled.div`
  width: 320px;
  height: 400px;
  float: right;
  padding-top: 20px;
  border-radius: 12px;
  background: rgba(255,255,255,0.92);
`;


export const Input0 = styled.input`
  display: block;
  width: 200px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  margin: 15px auto;
  color: #777;
`;

export const Button0 = styled.div`
  width: 200px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background: #1890FF;
  border-radius: 15px;
  margin: 35px auto;
  text-align: center;
`;

export const Logo = styled.div`
   top: 0;
   left: 0;
   margin: 25px auto;
   display: block;
   width: 190px;
   height: 60px;
   background: url(${logoPic});
   background-size: contain;
`;

export const LoginTitle = styled.div`
  width: 240px;
  font-size: 26px;
  font-family: "黑体";
  color: #000000;
  margin: 50px auto 40px;
`;

export const CInputsWrapper = styled.div`
  width: 320px;
`;

export const ContentInputWrapper = styled.div`
  width: 240px;
  height: 40px;
  margin: 10px auto 10px;
`;

export const Content = styled.div`
  float: left;
  width: 50px;
  margin-left: px;
  line-height: 30px;
  vertical-align: center;
  color: #000000;
`;

export const InputWrapper = styled.div`
  width: 180px;
  float: right;
`;

export const ButtonsWrapper = styled.div`
  width: 240px;
  margin: 50px auto 0;
`;

export const ButtonWrapper = styled.div`
  float: right;
  margin-left: 52.5px;
`;

export const BGContent = styled.div`
  text-align: right;
  font-size: 40px;
  font-family: "黑体";
  color: #FFF;
`;

export const BGVersion = styled.div`
  margin-top: 20px;
  text-align: right;
  font-size: 22px;
  font-family: "黑体";
  color: #FFF;
`;