import {fromJS} from 'immutable';
import * as constants from './constants';

const defaultState = fromJS({
  customerList: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.CHANGE_CUSTOMER_DATA:
      return state.merge({
        customerList: fromJS(action.customerList),
      });
    default:
      return state;
  }
}