import * as constants from './constants';
import axios from 'axios';
axios.defaults.withCredentials = true;

export const logout = () => ({
  type: constants.LOGOUT,
  value: false
});

export const getUserLogin = (user) => ({
  type: constants.GET_USER_LOGIN,
  value: true,
  user_id: user.user_id,
  username: user.nickname,
  permission: user.permission,
});

export const creatOneUser = (account, password) => {
  return (dispatch) => {
    axios.post('//simei-api.newitd.com/v1/user',{
      id: account,
      nickname: password
    }).then((res) => {
      alert('添加成功！')
    }).catch((error) => {
      alert('输入有误！')
    })
  }
};

export const logout0 = () => {
  return (dispatch) => {
    axios.post('//simei-api.newitd.com/v1/user/logout'
    ).then((res) => {
      dispatch(logout());
    }).catch((error) => {
      console.log(error.response.data);
      alert(error.response.data.msg)
    })
  }
};

export const getUser = () => {
  return (dispatch) => {
    axios.post("//simei-api.newitd.com/v1/user/get_login_user_info")
      .then((res) => {
        const result = res.data.data;
        console.log(result.user_id);
        dispatch(getUserLogin(result));
      })
  }
};

