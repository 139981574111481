import { createGlobalStyle } from 'styled-components';


export const GlobalStyleFont = createGlobalStyle`
  @font-face {font-family: "iconfont";
    src: url('./iconfont.eot?t=1563937420692'); /* IE9 */
    src: url('./iconfont.eot?t=1563937420692#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAAPIAAsAAAAAB/gAAAN5AAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCDHAqDPIMNATYCJAMQCwoABCAFhG0HPRv0BhHVmxHJfibE23uMAfZdFe2jsSZ4oPesN5lpby5ZSCch6Rn4E9U9iF9qAQRszrWb+xoVoUZy7tmjLySlYZanADg4ZroUmOXZbI4RaRFtkYADmw6ool4f9B/kBf2HsZsIGuJyCMAkQV6iavW6TdEoQDsBiF7du7ZHZ2SohnCBRuCuGKoQ83ChjenGFWAu/33xkcI1GLgkoGvDLtU6UfGNflNPjvONoxVFQJ9OAxijQAJ5gQLRrVLXARk58pKYXm1FcaDRGDBVWfE39Xw+hXEUnob/8kBgIEG4AaF+ABRVnBLeaEyBgDfFKTDgTT0+D4lWrTUQBMYDexRsnJeJwlCALWAvKfyE1uFWdLjtj19H/LUj7U5OV4/d0GkyyG7Xcqi9cdWRPbmddWuP788VaDp+Tc2eVvMWSxLMVVHN2h+2ZoIpzBPmjGtivMcznl+9rL1nA+M2WjNPC9FzZq2L5Y7evXunbFjYkTvmjNqXvN7xRXZnZzeYcafhnQn2zbvWzGRFO4+T/cC55a0f6inq8RYZXnS4S+byArHTVOX4kLhyZVI9XxMn96KqkyaJ+MLPYd8zMKDKzSBOdKlxM6vqzc4Bvg5VvD3KN7CNyh26l89Wf8oPHHS2ZAHhqpS/by6nu39rq07F0q/aeAZdjg7/E5P+PT2Bm58rTDnw+HCBvsbIrH6rtz7Y3ytX2YJ3y5YFBo3pNlOuPqjA2+oTijbp1iepW++mIqp6pRH5yu3ffxjN2KaPO6xTjyYTitIPAN8BuVfW5gSfPCFxfZBLZeSv/BsqxV562Daw7G9tKQDut/44S9b7DiiG0f8HvTP9x9E75imfCeRKuSxMC3CKghIAAzAT5u/yQIgnUwjCO4eCQBOugkEO4kGiSUMqbF5wYVEU3GgqgEkeNUZbhBqARKicQG6TJRAE2Q4GARwHSZCrSIV9DC4ifAY3QSWYOCJ0SouMbFRHRi3owPwh2nkipBQlwm/o34PmNJPjX8hbNENbNXn3FSfkKXpsH9+JEBDPI1yk1XAYZlh4TmilCiJLX9dU9aDKzmOhODJqQQfmD9HOE9HSogo/f0P/HjQ3JPWoX8hbrBzaqumAvPKmTkm3csr28Z0IAfE8wkXS4AAdMyzVjRJaqcIA29LXSjHqyquWF4xvWMBLTAH2UsIQUijhouwQy73ejdpPkSJyUQAAAAA=') format('woff2'),
    url('./iconfont.woff?t=1563937420692') format('woff'),
    url('./iconfont.ttf?t=1563937420692') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
    url('./iconfont.svg?t=1563937420692#iconfont') format('svg'); /* iOS 4.1- */
  }
  
  .iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`