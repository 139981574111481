import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { LoginWrapper, LoginBox, Input, Button } from './style';
import {actionCreators as loginActionCreators, actionCreators} from './store'

class customerModify extends PureComponent {
  render() {
      return (
        <LoginWrapper>
          <LoginBox>
            <Input placeholder='客户id' ref={(input) => {this.id = input}}/>
            <Input placeholder='客户名称'  ref={(input) => {this.name = input}}/>
            <Input placeholder='手机号' ref={(input) => {this.phone = input}}/>
            <Input placeholder='地址'  ref={(input) => {this.address = input}}/>
            <Input placeholder='干系人id'  ref={(input) => {this.principal = input}}/>
            <Input placeholder='状态'  ref={(input) => {this.status = input}}/>
            <Button onClick={() => this.props.modifyUserInfo(this.id,this.name,this.phone,this.address,this.principal,this.status)}>提交修改</Button>
          </LoginBox>
        </LoginWrapper>
      )
    }
}

const mapState = (state) => ({
  loginStatus: state.getIn(['login','login'])
});

const mapDispatch = (dispatch) => ({
  modifyUserInfo(idElem, nameElem, phoneElem, addressElem, principleElem, statusElem){
    dispatch(actionCreators.modifyUserInfo(idElem.value, nameElem.value, phoneElem.value, addressElem.value, principleElem.value, statusElem.value));
  },
  getUser() {
    dispatch(loginActionCreators.getUser())
  }
});

export default connect(mapState, mapDispatch)(customerModify);