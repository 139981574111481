import styled from 'styled-components';

export const LoginWrapper = styled.div`
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 48px;
  background: #F0F2F5;
`;

export const LoginBox = styled.div`
  width: 400px;
  height: 400px;
  margin: 120px auto;
  padding-top: 40px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0 0 8px rgba(0,0,0,.2);
  // text-align: center;
`;

export const TitleBox = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 20px;
  font-size: 17px;
  text-align: center;
`;

export const Input = styled.input`
  display: block;
  width: 200px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  margin: 15px auto;
  color: #777;
`;

export const Button = styled.div`
  width: 200px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background: #1890FF;
  border-radius: 15px;
  margin: 35px auto;
  text-align: center;
`;
