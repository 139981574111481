import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { actionCreators as initialActionCreators } from './store';

import { Layout, Breadcrumb, Button, InputNumber, Statistic  } from 'antd';

import { fundWrapper } from './style.js';

const { Content, Footer } = Layout;

class fundAdd extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      inputValue: 0,
      fundMoney: 0
    }
  }

  handleChange(e) {
    this.setState({
      inputValue:e
    })
  }

  componentDidMount() {
    this.props.getFund();
    this.setState({
      money: this.props.total_fund
    })
  }

  render() {
    const { total_fund } = this.props;
    const view_total_fund = parseFloat(total_fund).toFixed(2);

    if(this.props.permissionStatus === 1) {
      return (
        <Layout>
          <Content style={{padding: '0 50px', marginTop: 8}}>
            <Breadcrumb style={{margin: '16px 0'}}>
              <Breadcrumb.Item>资金管理</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{background: '#fff', padding: 24, minHeight: 790, margin: '0,auto'}}>
              <div style={{width: '480px',height: '240px', margin: '72px auto', padding: '48px', 'border': '1px dashed rgb(0,133,208)','border-radius': '24px'}}>
              <fundWrapper>
                <Statistic style={{width: '300px', float: 'left'}} title="资金余额 (元)" value={view_total_fund} precision={2} />
                <br/>
                <Button style={{float: 'right'}} type="primary" icon="sync" onClick={() => this.props.getFund()}>刷新</Button> {/*要么都加括号，要么都不加*/}
                <br/>
                <br/>
                <br/>
                <br/>
                <Button style={{float: 'right'}} type="primary" icon="upload"
                        onClick={() => this.props.addFund(this.state.inputValue)}>增加</Button> {/*用到钩子函数，防止自动运行*/}
                <InputNumber style={{width: '80px', float: 'right', 'margin-right': '10px'}} min={0} max={10000} defaultValue={0} step={100} onChange={this.handleChange.bind(this)}/>
              </fundWrapper>
              </div>
            </div>
          </Content>
          <Footer style={{textAlign: 'center'}}>Simei Manager ©2020</Footer>
        </Layout>
      );
    }else{
      return (
        <Redirect to='/'/>
      )
    }
  }
}



const mapState = (state) => ({
  total_fund: state.getIn(['fund','total_fund'])/100,
  permissionStatus: state.getIn(['login','user_permission']),
});

const mapDispatch = (dispatch) => ({
  getFund() {
    console.log('已更新');
    dispatch(initialActionCreators.getTotalFund());
  },
  addFund(money) {
    console.log('已添加',money);
    dispatch(initialActionCreators.addToFund(money*100));
  },
  changeInput(input) {
    dispatch(initialActionCreators.changeInputMoney(input));
  }
});

export default connect(mapState, mapDispatch)(fundAdd);