import axios from 'axios';
import { message } from "antd";

axios.defaults.withCredentials = true;

export const submitApply = (name_input, money_input, remark_input) => {
  return (dispatch) => {
    axios.post(
      "//simei-api.newitd.com/v1/application",
      {
        name: name_input,
        money: money_input*100,
        remark: remark_input
      })
      .then((res) => {
        message.success('提交申请成功');
      }).catch((error) => {
        message.error("输入内容无效");
        console.log(error);
    })
  }
};
